import React, { useEffect, useState } from 'react'
import PageLayout from '../../components/common/pageLayout'
import { Stack, Button, TextField, FormControl, InputLabel, Select, MenuItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { triggerAuth0User, triggerUpdateCompanyUser } from "../../../store/slices/users";
import _ from 'lodash';
import { useForm } from "react-hook-form";

const Home = ({ setPage, userDetails, existingUser }) => {


    const [isChange, setIsChange] = React.useState(false);

    const [accepted, setAccepted] = React.useState(false);
    const [firstName, setFirstName] = React.useState(userDetails?.firstName);
    const [email, setEmail] = React.useState(userDetails?.email);
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [lastName, setLastName] = React.useState(userDetails?.lastName);
    const [error, setError] = React.useState(false);
    const [touched, setTouched] = useState(false);
    //const [companyName, setCompanyName] = React.useState((existingUser && userDetails?.companyId )?"":  userDetails?.companyName);
    const dispatch = useDispatch();
    const errorMessage = "Your password must contain:" +
        "\nAt least 8 characters" +
        "\nAt least 3 of the following:" +
        "\nLower case letters (a-z)" +
        "\nUpper case letters (A-Z)" +
        "\nNumbers (0-9)" +
        "\nSpecial characters (e.g. !@#$%^&*)"
    const { handleSubmit, reset, formState: { errors } } = useForm({ mode: "all" });

    const handleTouch = () => {
        setTouched(true);
    };
    const onContinue = (e) => { 
        if (accepted)
        {
            let data = {
                firstName: _.trim(firstName),
                email: _.trim(email),
                userId: _.trim(userDetails.userId),
                companyId: _.trim(userDetails.companyId),
                lastName: _.trim(lastName),
                password: _.trim(password),
                companyUserInviteId: _.trim(userDetails.companyUserInviteId),
                status: userDetails.status,
                env: userDetails.env
            }
            if (!existingUser) {
                if (password != confirmPassword || (!passwordValidation(password))) {
                    setError(true);
                    e.preventDefault();
                    return
                }

                dispatch(triggerAuth0User(data))
                dispatch(triggerUpdateCompanyUser(data))
                setPage(2)
                e.preventDefault();
            }
            else { 
                dispatch(triggerUpdateCompanyUser(data))
                setPage(2)
            }
        }
    }

    const passwordValidation = (pwd) => {
        if (pwd.length < 8)
            return false

        let lower = false
        let upper = false
        let numbers = false
        let special = false
        let isValid = 0
        _.map(pwd, c => {
            if (!isNaN(c)) {
                if (!numbers) { numbers = true; isValid++ }
            }

            else if (c === _.upperCase(c)) { if (!upper) { upper = true; isValid++ } }
            else if (c === _.lowerCase(c)) { if (!lower) { lower = true; isValid++ } }
            else { if (!special) { special = true; isValid++ } }
        })
        //   pwd.foreach { c =>
        //     if (c.isDigit)       numbers = true
        //     else if (c.isLower)  lower = true
        //     else if (c.isUpper)  upper = true
        //     else                 special = true
        //   }

        return isValid >= 3 ? true : false
    }
    const handlePasswordValidation = (e) => {
        console.log(e)
    }
    return (
        <div style={{ marginTop: '90px', width: '385px', overflow: 'hidden' }}>
            <PageLayout title=''>
                <form onSubmit={(e) => handleSubmit(onContinue(e))}>
                    <Stack justifyContent={"center"} direction={"column"} alignItems={"center"} spacing={2}>
                        <TextField required
                            id="txtEmail"
                            value={email}
                            label="Email"
                            variant="outlined"
                            size="small"
                            disabled
                            onChange={(e) => { setEmail(e.target.value.trimStart()); setIsChange(true) }}
                        />
                        <TextField required
                            id="txtFirstName"
                            value={firstName}
                            label="First Name"
                            variant="outlined"
                            size="small"
                            disabled={true}
                            onChange={(e) => { setFirstName(e.target.value.trimStart()); setIsChange(true) }}
                        />
                        <TextField required
                            id="txtLastName"
                            value={lastName}
                            label="Last Name"
                            variant="outlined"
                            size="small"
                            disabled={true}
                            onChange={(e) => { setLastName(e.target.value.trimStart()); setIsChange(true) }}
                        />
                        {!existingUser &&
                            <>
                                <TextField required
                                    id="txtPassword"
                                    value={password}
                                    label="Password"
                                    variant="outlined"
                                    size="small"
                                    type='password'
                                    inputProps={{ maxLength: 60 }}
                                    onChange={(e) => { setPassword(e.target.value.trimStart()); setIsChange(true) }}
                                    onBlur={handlePasswordValidation}
                                    onFocus={handleTouch}
                                    title={errorMessage}
                                    helperText={touched && errorMessage}
                                    error={password.length > 7 ? !passwordValidation(password) : false}
                                />
                                <TextField required
                                    id="txtConfirmPassword"
                                    value={confirmPassword}
                                    label="Confirm Password"
                                    variant="outlined"
                                    size="small"
                                    type='password'
                                    onChange={(e) => { setConfirmPassword(e.target.value.trimStart()); setIsChange(true) }}
                                    helperText={touched && "Password does not match!"}
                                    error={(password != confirmPassword) ? true : false}
                                />
                            </>
                        }
                        <div style={{ display: 'flex', width: '385px' }}>
                            <input checked={accepted} style={{ zIndex: 1000, height: '20px', width: '20px!important', marginRight: '4px' }} type='checkbox' onChange={(e) => setAccepted(e.target.checked)} />
                            <span className='accept-terms' style={{ display: 'flex', flexDirection: 'column' }}>{'I acknowledge that I have reviewed and accept the '}
                                <span style={{ textAlign: 'left' }}><a href='https://static.rightresponse.ai/agreements/TOS-20240101.pdf' target='_blank'>Terms of Services</a>
                                    <span>{', '}<a href='https://static.rightresponse.ai/agreements/EULA-20240101.pdf' target='_blank'> {'End User License Agreement'}</a> </span>
                                </span>
                                <span>{' and '} <a href='https://static.rightresponse.ai/agreements/PP-20240101.pdf' target='_blank'> {'Privacy Policy.'}</a></span>
                            </span>
                        </div>
                        <Button disabled={!accepted} type="submit" className="button" sx={{ textTransform: 'none', fontSize: '16px' }} >Continue</Button>
                    </Stack>
                </form>
            </PageLayout>
        </div>
    )
}

export default Home