import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { BeatLoader } from "react-spinners";



const Spinner = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress && (
            <div className="modal fade-in" style={{ position: 'fixed', display: 'flex', top: 60, width: 100, height: 'auto', left: 'calc(50% - 25px)', zIndex: 3500 }}>
                <div>
                    <BeatLoader color="#3681EB" />
                </div>
            </div>
        )
    );
};

export default Spinner;
