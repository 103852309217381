import { Button, Box, Divider, Stack, Typography, TextField, useTheme, Grid, Checkbox, DialogContent, DialogActions } from '@mui/material'
import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { MaterialReactTable } from 'material-react-table';
import { NavLink, useNavigate } from "react-router-dom";
import { convertArrayToDictionary } from "../../common/utils";
import { getPaymentLink, stripeSelector, getProducts, getPriceList, getCouponDetail } from "../../store/slices/stripe";
import _ from 'lodash';
import AppConfig from "../../appConfig";
import WarningModal from './warningModal';

const ChangeSubscription = ({ selectedProduct = "mrt" }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getPriceList());
        dispatch(getProducts());
    }, [])
    const { checkoutUrl, stripeProducts, priceList, signupData, couponDetail } = useSelector(stripeSelector);
    const priceData = React.useMemo(() => convertArrayToDictionary(priceList, 'id'), [priceList]);
    const [couponCode, setCouponCode] = React.useState();
    const [validCoupon, setValidCoupon] = useState(null);
    const [finalAmount, setFinalAmount] = useState(0);
    const coreBundleAmt = React.useMemo(() => priceData[_.find(stripeProducts, it => it.code === "CoreBundle" && it.status == 1)?.priceId]?.unitAmount / 100, [stripeProducts, priceData]);
    const [openWarning, setOpenWarning] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    useEffect(() => {
        if (checkoutUrl && checkoutUrl != "error") {
            window.open(checkoutUrl, "_self");
        }
        else if (checkoutUrl && checkoutUrl == "error") {
            navigate("/error");
        }
    }, [checkoutUrl]);

    const [locationQty, setLocationQty] = useState(0);
    const [competitorQty, setcompetitorQty] = useState(0);

    const [locationAmt, setlocationAmt] = useState(0);
    const [competitorAmt, setcompetitorAmt] = useState(0);


    const [selectedItem, setSelectedItem] = useState({ '1': false, '2': false, '3': false, '4': false });

    useEffect(() => {
        var cnt = _.filter(selectedItem, it => { return it == true })?.length;
        const amt = coreBundleAmt * locationQty;
        if (coreBundleAmt) {
            if (cnt > 1 && (locationAmt > amt)) {
                setOpenWarning(true);
            }
            else if (cnt > 1) {
                setOpenConfirm(true);
            }
        }
    }, [selectedItem])

    useEffect(() => {
        if (couponDetail) {
            if (couponDetail?.coupon?.valid) {
                setValidCoupon(null);
                let amount = locationAmt + competitorAmt;
                if (couponDetail?.coupon?.amountOff) {
                    let discount = couponDetail?.coupon?.amountOff / 100
                    setFinalAmount(amount - discount);
                }
                else if (couponDetail?.coupon?.percentOff) {
                    let discount = couponDetail?.coupon?.percentOff * amount / 100
                    setFinalAmount(amount - discount);
                }
            }
            else {
                setValidCoupon("This coupon is not valid.");
            }
        }

    }, [couponDetail, locationAmt, competitorAmt]);
    // useEffect(() => {setFinalAmount(locationAmt+competitorAmt)},[locationAmt,competitorAmt])
    const tableData = React.useMemo(() => {

        let finalData = [];
        let dataRows = [];
        _.map(stripeProducts, (item, i) => {
            const amount = priceData?.[item?.priceId]?.unitAmount / 100;
            const name = item?.displayName;
            const qty = selectedProduct == _.toLower(item.code) ? 1 : 0;
            const isCompetitor = item.code === "Competitor";

            dataRows.push({
                name: name,
                id: isCompetitor ? 1 : 0,
                productId: item?.stripeProductId,
                itemId: item?.priceId,
                quantity: qty,
                amount: amount,
                diff: 0,
                total: qty * amount,
                seq: item?.sequence
            });

            setSelectedItem((prev) => ({ ...prev, [item?.sequence]: qty > 0 }))


        });
        let groupedData = _.groupBy(dataRows, 'id')
        _.map(groupedData, (d, key) => {
            let qty = d.find(it => it.quantity > 0)?.quantity ?? 0;
            let amount = _.sumBy(_.filter(d, it => it.quantity > 0), 'amount') ?? 0;
            const isCompetitor = parseInt(key) === 1;
            finalData.push({
                subItems: _.orderBy(d, ['seq']),
                name: d.length > 1 ? "Your Locations" : d?.[0]?.name,
                id: parseInt(key),
                itemId: d[0].itemId,
                productId: d[0].productId,
                quantity: qty,
                amount: d[0].amount,
                diff: 0,
                total: qty * amount,
            }); if (isCompetitor) {
                setcompetitorQty(qty);
                setcompetitorAmt(qty * amount);
            }
            else {
                setLocationQty(qty);
                setlocationAmt(qty * amount);
            }

        })
        finalData = _.orderBy(finalData, ['id'])

        return finalData;
    }, [stripeProducts, priceData]);

    const onSave = () => {
        const amt = coreBundleAmt * locationQty;
        if (locationAmt > amt) {
            setOpenWarning(true);
        }
        else {
            let subscriptionItems = [];
            _.map(tableData, (r, i) => {
                let qty = parseInt(document.getElementById(r.id).value);
                if (i === 0) {
                    _.map(r.subItems, d => {
                        let itemQty = qty
                        if (selectedItem[d.seq]) {
                            subscriptionItems.push({ ItemId: d.itemId, Quantity: itemQty, productId: d.productId })
                        }
                    })
                }
                else {
                    if (qty > 0) {
                        subscriptionItems.push({ ItemId: r.itemId, Quantity: qty, productId: r.productId })

                    }
                }
            })
            if (subscriptionItems?.length > 0) {
                let data = _.cloneDeep(signupData);
                data['Items'] = subscriptionItems;
                if (couponDetail?.coupon) {
                    data['CouponCode'] = couponDetail.coupon.id;
                }

                dispatch(getPaymentLink(data));
            }
        }
    }
    const getAmount = (quantity) => {
        let locationItems = tableData[0]?.subItems;
        var amt = 0;
        _.map(locationItems, item => {
            if (selectedItem[item.seq]) {
                amt += (quantity * item.amount);
            }
        })
        return amt;
    }

    const setNewDefaultRows = (quantity, rowIndex, amount) => {
        if (rowIndex == 0) {
            amount = getAmount(quantity);
            setLocationQty(quantity);
            setlocationAmt(amount);

        }
        else {
            setcompetitorQty(quantity);
            setcompetitorAmt(quantity * amount);

        }
    }
    const setCheckBox = (e, row, total) => {
        if (!e) {
            let amount = total - (locationQty * row.amount);
            setlocationAmt(amount);
        }
        else {
            let amount = total + (locationQty * row.amount);
            if (row.seq === 1) {
                setSelectedItem((prev) => ({ ...prev, [2]: false, [3]: false, [4]: false }));
                setlocationAmt(0);
                amount = locationQty * row.amount;
            }
            else {
                setSelectedItem((prev) => ({ ...prev, [1]: false }));
                if (selectedItem[1])
                    amount = amount - (locationQty * coreBundleAmt)
            }
            setlocationAmt(amount);
        }
        setSelectedItem((prev) => ({ ...prev, [row.seq]: e }));
    }
    const renderCell = (cell) => {
        let id = cell.row.original.id
        switch (cell.column.id) {
            case 'quantity':
                return (<div id='product-qty' style={{ width: '70px', marginTop: cell.row.original.subItems.length > 1 ? '-100px' : '0px' }}>{
                    <TextField
                        type="number"
                        id={cell.row.original.id}
                        defaultValue={id == 0 ? locationQty : competitorQty}
                        InputProps={{ inputProps: { min: 0, max: 999 } }}
                        onChange={(e) => setNewDefaultRows(e.target.value < 0 ? 0 : e.target.value > 999 ? 999 : e.target.value, cell.row.original.id, cell.row.original.amount)}
                        onKeyUp={(e) => e.target.value > 999 ? e.target.value = 999 : (e.key == '-' || e.target.value < 0) ? e.target.value = 0 : e.target.value}
                    />
                }</div>)
            case 'name':
                if (cell.row.original.subItems.length > 1) {
                    return (
                        <>
                            <Typography fontWeight={600}>{cell.renderedCellValue}</Typography>

                            {_.map(cell.row.original.subItems, row => {

                                return (
                                    <Stack sx={{ marginLeft: '20px' }} direction={'row'} spacing={1}>
                                        <Checkbox
                                            onChange={(e) => { setCheckBox(e.target.checked, row, locationAmt) }}
                                            checked={selectedItem[row.seq]}
                                        />
                                        <div style={{ marginTop: '10px', fontWeight: 500 }}>{row.name}
                                            <span style={{ marginLeft: '10px' }}>{'($' + row.amount + ')'}</span>
                                        </div></Stack>
                                )
                            })}
                        </>
                    );
                }
                else {
                    return (
                        <Typography fontWeight={600}>{cell.renderedCellValue}</Typography>

                    );
                }
            case 'total': return (<div style={{ textAlign: 'right', marginTop: cell.row.original.subItems.length > 1 ? '-100px' : '0px' }}>{'$' + (id == 0 ? locationAmt : competitorAmt)}</div>);


            default: return (<div style={{ textAlign: 'right' }}>{'$' + cell.renderedCellValue}</div>)
        }

    }


    const summaryTableColumns = React.useMemo(() => {
        let columns =
            [
                {
                    header: '',
                    accessorKey: 'name',
                    sequence: 1,
                    Size: 350,
                    maxSize: 350,
                    minSize: 350,
                    Cell: renderCell,
                    Footer: (d) => {
                        return [
                            <div style={{ paddingTop: 4, fontSize: "16px", fontWeight: 700, }} >
                                {"Total"}
                            </div>,
                        ];
                    },
                },
                {
                    header: 'Quantity',
                    accessorKey: 'quantity',
                    sequence: 2,
                    Size: 70,
                    maxSize: 70,
                    minSize: 70,
                    Cell: renderCell
                },
                {
                    header: 'Amount',
                    accessorKey: 'total',
                    sequence: 3,
                    Size: 50,
                    maxSize: 50,
                    minSize: 50,
                    Cell: renderCell,
                    Footer: (d) => {
                        let amount = finalAmount > 0 ? finalAmount : (locationAmt + competitorAmt);
                        return [
                            <div style={{ textAlign: 'right', paddingTop: 4, paddingRight: 8, fontSize: "16px", fontWeight: 700, }} >
                                {'$' + amount}
                            </div>,
                        ];
                    },
                }
            ]


        return _.orderBy(columns, 'sequence');
    }, [locationAmt, competitorAmt, finalAmount, selectedItem])
    const handleCancel = () => {
        navigate('/cancel')
    }
    const checkCoupon = () => {
        setFinalAmount(locationAmt + competitorAmt)
        dispatch(getCouponDetail(couponCode))
    }
    const handleConfirmYes = () => {
        setCheckBox(true, tableData[0].subItems[0], locationAmt);
        setOpenConfirm(false);
    }
    const handleOk = () => {
        setCheckBox(true, tableData[0].subItems[0], locationAmt);
        setOpenWarning(false);
    }
    return (
        <>
            <div style={{ padding: '20px' }}>
                <Typography variant='h4'>{"Manage Products"}</Typography>
                <Stack spacing={2} pt={3}>
                    <Box sx={{ width: '750px', overflowX: 'auto' }}>
                        <MaterialReactTable data={tableData} columns={summaryTableColumns}
                            enableColumnActions={false}
                            positionActionsColumn="last"
                            enableFullScreenToggle={false}
                            enableDensityToggle={false}
                            enableColumnFilters={false}
                            enableSorting={false}
                            enablePagination={false}
                            enableTopToolbar={false}
                            enableBottomToolbar={false}
                            initialState={{
                                columnOrder: [
                                    'name',
                                    'quantity',
                                    'total',
                                    'diff',
                                    'otfAmt'
                                ]
                            }
                            }
                            muiTableContainerProps={{
                                sx: { overflowY: 'hidden', overflowX: 'auto', backgroundColor: '#fff' },
                            }}
                            muiTablePaperProps={{
                                className: "std_table tbl-subscription"
                            }}
                        />
                        {AppConfig.appMode === 'dev' &&

                            <>
                                <Divider />
                                <Stack direction={'row'} spacing={4} sx={{ padding: '10px' }}>
                                    <Typography variant='h6'>{"Apply Coupon:"}</Typography>
                                    <TextField sx={{ minWidth: '150px' }} id='txtCoupon'
                                        onChange={(e) => { setCouponCode(e.target.value.trimStart()) }}></TextField>
                                    <Button onClick={checkCoupon} disabled={_.isEmpty(couponCode)} variant='outlined' color='primary' >{"Apply"}</Button>

                                </Stack>
                                {validCoupon &&
                                    <Typography color={'red'}>{validCoupon}</Typography>
                                }
                            </>
                        }

                        <Divider />
                        <Stack direction={'row'} style={{ display: 'flex', justifyContent: "space-between", marginTop: 30 }}>
                            <Stack direction={'row'} spacing={2}>
                                <Button onClick={onSave} disabled={(locationAmt + competitorAmt) <= 0} variant='contained' color='primary'>{"Make Payment"}</Button>

                                <Button onClick={handleCancel} variant='outlined' color='secondary' >{"Cancel"}</Button>
                            </Stack>


                        </Stack>
                    </Box>


                </Stack>
                {openWarning &&
                    <WarningModal open={openWarning} sx={{ width: '550px !important', p: 0 }}  >
                        <DialogContent sx={{ height: 150 }}>
                            <Typography variant='subtitle1' color={'text.secondary'}>{'You should opt for "Core Bundle". Only for $' + coreBundleAmt * locationQty + ', you will get Intelligent Review Responder + Review Analytics (with Sentiment Analysis) + Google Maps Rank Tracker.'}</Typography>
                        </DialogContent>
                        <Divider style={{ margin: 0 }} />
                        <DialogActions sx={{ p: 0, px: 3, py: 2 }}>
                            <Stack direction={'row'} spacing={2} justifyContent={'start'} sx={{ width: '100%' }}>
                                <Button variant='contained' color='primary' onClick={() => { handleOk() }} sx={{ width: '80px' }}>Ok</Button>
                            </Stack>
                        </DialogActions>

                    </WarningModal>
                }

                {openConfirm &&
                    <WarningModal open={openConfirm} sx={{ width: '550px !important', p: 0 }}  >

                        <DialogContent sx={{ height: 150 }}>
                            <Typography variant='subtitle1' color={'text.secondary'}>{'You should opt for "Core Bundle". Only for $' + coreBundleAmt * locationQty + ', you will get Intelligent Review Responder + Review Analytics (with Sentiment Analysis) + Google Maps Rank Tracker.'}</Typography>
                            <Typography variant='subtitle1' color={'text.secondary'}>{'Do you want to proceed with Core Bundle?'}</Typography>
                        </DialogContent>
                        <Divider style={{ margin: 0 }} />
                        <DialogActions sx={{ p: 0, px: 3, py: 2 }}>
                            <Stack direction={'row'} spacing={2} justifyContent={'start'} sx={{ width: '100%' }}>
                                <Button variant='contained' color='primary' onClick={() => { handleConfirmYes() }} sx={{ width: '80px' }}>Yes</Button>
                                <Button variant='outlined' color='secondary' onClick={() => { setOpenConfirm(false) }} sx={{ width: '80px' }}>No</Button>
                            </Stack>
                        </DialogActions>

                    </WarningModal>
                }
            </div>
        </>
    )

}

export default ChangeSubscription 
