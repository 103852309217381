import React, { useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react'
import Customer from './customer';
import Business from './business';
import Welcome from './welcome';
import Header from './header';
import Stepper from './stepper';
import Footer from './footer';
import { useNavigate } from 'react-router'
import _ from 'lodash';
import AppConfig from '../../../appConfig';
import useMobileOrTablet from '../../../common/detectMobile';
import MobileHeader from '../../components/common/mobileHeader'
import MobileFooter from '../../components/common/mobileFooter'
import TitleHelper from '../../components/common/titleHelper';
import { getRedirectUrl } from '../../../common/utils';
import { setselectedProduct } from "../../../store/slices/users";
import { setSignupData } from "../../../store/slices/stripe";
import { useDispatch } from 'react-redux';
import ManageProduct from '../manageProduct';

const Home = ({ page, setPage, existingUser, signUpUser, companyDeleted, hasSubscription,selectedProduct = null }) => {
    const dispatch = useDispatch();
    const isMobile = useMobileOrTablet();
    const { user } = useAuth0();
    const navigate = useNavigate();
    const createNewCompany = () => {
        navigate('/company?' + localStorage.getItem('queryString'), { replace: true })
    }
    useEffect(() => {
        dispatch(setselectedProduct(selectedProduct))
        if(selectedProduct==='mrt' && !hasSubscription)
        {
        setPage(4);
        dispatch(setSignupData(signUpUser));
        }
    }, [selectedProduct,signUpUser])
    const getLink = () => {
        return (<><div style={{ marginTop: '30px' }}>
            <span >
                <a className='data-link' onClick={() => window.location.href = getRedirectUrl(signUpUser?.environment ?? "dev") + '#/?companyId=' + signUpUser?.companyId + '&userId=' + signUpUser?.userId}>Click here</a>
            </span> to login in your existing subscription.
        </div>
            {_.includes(user?.["rightresponse.ai/roles"]?.roles, "IRUser") &&
                <>
                    <div style={{ marginTop: '10px' }}>Or</div>
                    <div style={{ marginTop: '10px' }}>
                        <span>
                            <button className='data-link'
                                style={{ background: 'none', border: 'none', fontSize: 16, textDecoration: 'underline' }}
                                onClick={(e) => { createNewCompany() }} > Click here </button>
                        </span> to create a new subscription.
                    </div>
                </>
            }</>
        )
    }

    const HEADER = useMemo(() => isMobile ? TitleHelper : Header, [isMobile])
    return (

        <div className='main-container'>
            {!isMobile && <div className='sidebar'>
                <Stepper page={page} />
                <Footer />
            </div>}
            {isMobile && <MobileHeader />}
            <div className={isMobile ? "mobile" :page === 4?'' :'main-area'} style={{ position: 'relative' }}>
                {(!signUpUser?.companyId || companyDeleted) &&

                    <>
                        {page === 1 && signUpUser?.signUpId != null &&
                            <div>
                                <HEADER page={page} title={'Let’s get to know each other'} subtitle={'Please tell us more about yourself'} />
                                <div style={{ marginTop: isMobile ? '-300px' : '0px' }}>
                                    <Customer setPage={setPage} userDetails={signUpUser} existingUser={existingUser} companyDeleted={companyDeleted} />
                                </div>
                            </div>
                        }
                        {page === 2 &&
                            <div>
                                <HEADER setPage={setPage} page={page} title={'Tell us more about your business'} subtitle={'Please complete the short survey to help us understand your business'} />
                                <div style={{ marginTop: isMobile ? '-340px' : '0px', position: isMobile ? 'relative' : '' }}>
                                    <Business setPage={setPage} page={page}
                                        userDetails={signUpUser} existingUser={existingUser} />
                                </div>
                            </div>
                        }

                    </>
                }
                {signUpUser?.companyId && !companyDeleted && 

                    <>
                        {page === 1 && signUpUser?.signUpId != null &&

                            <HEADER page={page} title={"You already have a subscription."}
                                subtitle={getLink()} />
                        }
                    </>
                }
                {page === 3 &&
                    <>
                        <HEADER page={page} title={"You’re all set!"} subtitle={'We have sent a verification email to you. Please check your email and verify.'} />
                        <Welcome setPage={setPage} signUpId={signUpUser?.signUpId} companyId={signUpUser?.companyId} userId={signUpUser?.userId} />
                    </>
                }
                {page === 4 &&
                    <>
                        <ManageProduct selectedProduct={selectedProduct} />
                    </>
                }
            </div>
            {isMobile && <MobileFooter />}
        </div>

    )
}

export default Home
