import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import AppConfig from '../../appConfig';
import { getAuthToken } from '../../common/utils';

export const getAxios = (url, axiosRequestConfig) => {
    axiosRequestConfig.headers = {
        userId: localStorage.getItem('userId') ? localStorage.getItem('userId') : AppConfig.env('userId'),
        connectionId: sessionStorage.getItem('tabId') ? sessionStorage.getItem('tabId') : AppConfig.env('connectionId'),
        authorization: `Bearer ${getAuthToken()}`,
        env: AppConfig.appMode === 'local' ? 'dev' : AppConfig.appMode,
        queryString: localStorage.getItem('queryString'),
        'Url': url,
        'Step': 'DataCall',
        'IsWebService': '0',
        'elapsedTime': 0
    };
    url = AppConfig.apiUrl + url;
    var respLoader = trackPromise(
        axios({
            method: 'get',
            url: url,
            data: axiosRequestConfig.params,
            headers: axiosRequestConfig.headers,
            callTime: axiosRequestConfig.params.callTime,
        })
            .then((response) => {
                return response?.data;;
            })
            .catch((response) => {
                console.log(response);
            })
    );
    return respLoader;
}

export const postAxios = (url, axiosRequestConfig) => {
    axiosRequestConfig.headers = {
        userId: localStorage.getItem('userId') ? localStorage.getItem('userId') : AppConfig.env('userId'),
        connectionId: sessionStorage.getItem('tabId') ? sessionStorage.getItem('tabId') : AppConfig.env('connectionId'),
        authorization: `Bearer ${getAuthToken()}`,
        env: AppConfig.appMode === 'local' ? 'dev' : AppConfig.appMode,
        queryString: localStorage.getItem('queryString'),
        'Url': url,
        'Step': 'DataCall',
        'IsWebService': '0',
        'elapsedTime': 0
    };
    url = AppConfig.apiUrl + url;
    if (!axiosRequestConfig.params.callTime) {
        axiosRequestConfig.params = { ...axiosRequestConfig.params, callTime: new Date() }
    }
    var respLoader = trackPromise(
        axios({
            method: 'post',
            url: url,
            data: axiosRequestConfig.params,
            headers: axiosRequestConfig.headers,
            callTime: axiosRequestConfig.params.callTime,
        })
            .then((response) => {
                return response?.data;;
            })
            .catch((response) => {
                console.log(response);
            })
    );
    return respLoader;
}


