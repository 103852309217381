import { useEffect, useState } from 'react';

/**
 * Custom React hook to detect whether the current device is a mobile or PC based on various conditions.
 *
 * @returns {boolean} True if the device is a mobile or tablet, false if it's a PC.
 */
const useMobileOrTablet = () => {
    const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            // Check user agent string
            const userAgent = navigator.userAgent;
            const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

            // Check media query for touch devices
            const isTouchMediaQuery = window.matchMedia('(hover: none)').matches;

            // Check screen width
            const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const mobileTabletThreshold = 768;

            // Update the state based on user agent, media queries, and screen width
            setIsMobileOrTablet(
                mobileRegex.test(userAgent) ||
                isTouchMediaQuery ||
                screenWidth < mobileTabletThreshold
            );
        };

        // Run the check when the component mounts
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return isMobileOrTablet;
};

export default useMobileOrTablet;
