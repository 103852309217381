import { createSlice } from "@reduxjs/toolkit"
import { getAxios ,postAxios} from "./axiosActions"
import _ from 'lodash'; 

const initialState = { 
    loading:true, 
    signupCreated:false,
    couponDetail:null,
    requestData: {},
}



const signupRequestSlice = createSlice({
    name: "signupRequest",
    initialState,
    reducers: {  
        validateSignupRequestSuccess: (state, { payload }) => {
            state.clickStatus = payload?.clickStatus
            state.requestData = payload?.signupRequest
        },
        createSignupSuccess: (state, { payload }) => {
            state.signupCreated = payload?.signupCreated ;
            state.requestData = payload?.signupRequest;
            state.loading = false;
        },
    }
})

export default signupRequestSlice.reducer
export const signupRequestSelector = state => state.signupRequest;
export const { validateSignupRequestSuccess ,createSignupSuccess
} = signupRequestSlice.actions

export const validateSignupRequest = (code) => {
    return async dispatch => {
        try {
            const response = await getAxios('api/NewUser/ValidateSignupRequest?code='+code, { params: {} });
            dispatch(validateSignupRequestSuccess(response));
        } catch (error) {
            
        }
    }
};
 
export const createSignup = (data) => {
    return async dispatch => { 
        try {
            const response = await postAxios('api/NewUser/CreateSignUp', { params: data });
            dispatch(createSignupSuccess(response));
        } catch (error) {
             
        }
    }
};