import React, { useEffect, useState, useMemo } from 'react'
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { signupRequestSelector, createSignup } from "../../../store/slices/signupRequest";
import PageLayout from '../../components/common/pageLayout'
import { Stack, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import AppConfig from '../../../appConfig';
import { getRedirectUrl } from '../../../common/utils';
const Home = ({ page, setPage, queryString }) => {
    const dispatch = useDispatch();
    const { requestData, signupCreated, loading } = useSelector(signupRequestSelector);
    
  
    const errorMessage = "Your password must contain:" +
        "\nAt least 8 characters" +
        "\nAt least 3 of the following:" +
        "\nLower case letters (a-z)" +
        "\nUpper case letters (A-Z)" +
        "\nNumbers (0-9)" +
        "\nSpecial characters (e.g. !@#$%^&*)";


    const [accepted, setAccepted] = React.useState(false);
    const [firstName, setFirstName] = React.useState();
    const [lastName, setLastName] = React.useState();
    const [companyName, setCompanyName] = React.useState();
    const [email, setEmail] = React.useState();
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [invalidPassword, setIsValidPassword] = useState(false);
    const { handleSubmit} = useForm();
    useEffect(() => {
        setFirstName(requestData?.firstName);
        setLastName(requestData?.lastName);
        setCompanyName(requestData?.companyName);
        setEmail(requestData?.email);
    }, [requestData])

    useEffect(() => {
        if(!loading)
        {
            if (signupCreated  ) { 
                let url = getRedirectUrl(AppConfig.appMode) + '#/?companyId=' + requestData?.companyId + '&userId=' + requestData?.userId;
                window.open(url, "_self");
            }
            else{
                setPage(2);
            }
        }
        
    }, [loading])
     

    const passwordValidation = (pwd) => {
        if (pwd.length < 8)
        { 
            setIsValidPassword(true)
            return false;
        }  
        let lower = false
        let upper = false
        let numbers = false
        let special = false
        let isValid = 0
        _.map(pwd, c => {
            if (!isNaN(c)) {
                if (!numbers) { numbers = true; isValid++ }
            }

            else if (c === _.upperCase(c)) { if (!upper) { upper = true; isValid++ } }
            else if (c === _.lowerCase(c)) { if (!lower) { lower = true; isValid++ } }
            else { if (!special) { special = true; isValid++ } }
        })
        setIsValidPassword(isValid >= 3 ? false : true);
        return isValid >= 3 ? true : false;
    }
    
    const onContinue = () => {
        if (accepted && passwordValidation(password)) {
            let data = {
                firstName: _.trim(firstName),
                lastName: _.trim(lastName),
                companyName: _.trim(companyName),
                email: _.trim(email),
                password: password,
            }
            dispatch(createSignup(data));
        }
    }
    return (
        <> 
            <div style={{ marginTop: '90px', width: '385px' }} >
                <PageLayout title=''>
                <form onSubmit={handleSubmit(onContinue)}>
                        <Stack justifyContent={"center"} direction={"column"} alignItems={"center"} spacing={2}>
                            <TextField required
                                id="txtEmail"
                                value={email}
                                label="Email"
                                variant="outlined"
                                size="small"
                                disabled
                            />
                            <TextField required
                                id="txtFirstName"
                                value={firstName}
                                label="First Name"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => { setFirstName(e.target.value.trimStart()) }}
                            />
                            <TextField required
                                id="txtLastName"
                                value={lastName}
                                label="Last Name"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => { setLastName(e.target.value.trimStart()) }}
                            />
                            <TextField required
                                id="txtCompanyName"
                                value={companyName}
                                label="Company Name"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => { setCompanyName(e.target.value.trimStart()) }}
                            /> 
                            
                            <TextField required
                                id="txtPassword"
                                value={password}
                                label="Password"
                                variant="outlined"
                                size="small"
                                type='password'
                                inputProps={{ maxLength: 60 }}
                                onChange={(e) => { setPassword(e.target.value.trimStart()) }}
                                title={errorMessage}
                                helperText={invalidPassword && errorMessage}
                                error={invalidPassword }
                            />
                            <TextField required
                                id="txtConfirmPassword"
                                value={confirmPassword}
                                label="Confirm Password"
                                variant="outlined"
                                size="small"
                                type='password'
                                onChange={(e) => { setConfirmPassword(e.target.value.trimStart()) }}
                                helperText={confirmPassword.length > 7 && password != confirmPassword && "Password does not match!"}
                                error={ ( confirmPassword.length > 7 && password != confirmPassword) ? true : false}
                            />
                            <div style={{ display: 'flex', width: '385px' }}>
                                <input checked={accepted} style={{ zIndex: 1000, height: '20px', width: '20px!important', marginRight: '4px' }} type='checkbox' onChange={(e) => setAccepted(e.target.checked)} />
                                <span className='accept-terms' style={{ display: 'flex', flexDirection: 'column' }}>{'I acknowledge that I have reviewed and accept the '}
                                    <span style={{ textAlign: 'left' }}><a href='https://static.rightresponse.ai/agreements/TOS-20240101.pdf' target='_blank'>Terms of Services</a>
                                        <span>{', '}<a href='https://static.rightresponse.ai/agreements/EULA-20240101.pdf' target='_blank'> {'End User License Agreement'}</a> </span>
                                    </span>
                                    <span>{' and '} <a href='https://static.rightresponse.ai/agreements/PP-20240101.pdf' target='_blank'> {'Privacy Policy.'}</a></span>
                                </span>
                            </div>
                            <Button type='submit' disabled={(!accepted || (password !== confirmPassword))} className="button" sx={{ textTransform: 'none', fontSize: '16px' }} >Continue</Button>
                        </Stack> 
                        </form>
                </PageLayout>

            </div>
        </>
    )
}

export default Home
