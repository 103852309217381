import React from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Box, IconButton, Tooltip } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import _ from 'lodash'
const MobileHeader = () => {
  const { logout, isAuthenticated, user } = useAuth0();
   

  
  return (
    <div className='footer' style={{position:'absolute',top:0,left:0,width:'100%',paddingLeft:24,paddingRight:24,alignItems:'center'}}>
       <div className="logo" style={{top:12}}>
    <svg
      className="right-response"
      width="135"
      height="18"
      viewBox="0 0 135 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.607422 13.5503V0.513242H5.8693C8.71256 0.513242 10.6142 2.20806 10.6142 4.85271C10.6142 6.5289 9.74648 7.86986 8.3987 8.52171L10.9466 13.5503H7.88174L5.70314 9.17356H3.37683V13.5503H0.607422ZM5.64775 2.84128H3.37683V6.84552H5.64775C6.9586 6.84552 7.78943 6.0633 7.78943 4.85271C7.78943 3.62351 6.9586 2.84128 5.64775 2.84128Z"
        fill="white"
      />
      <path
        d="M13.4888 2.95303C12.6395 2.95303 11.9748 2.3198 11.9748 1.48171C11.9748 0.624988 12.6395 -0.00823975 13.4888 -0.00823975C14.3381 -0.00823975 15.0027 0.624988 15.0027 1.48171C15.0027 2.3198 14.3381 2.95303 13.4888 2.95303ZM12.1779 13.5503V3.97737H14.7996V13.5503H12.1779Z"
        fill="white"
      />
      <path
        d="M23.5742 5.2997L23.7958 3.97737H26.0851V13.2709C26.0851 15.6362 24.645 17.3869 21.0079 17.3869C18.3677 17.3869 16.5584 16.1763 16.4291 13.96H19.0508C19.2908 14.8726 20.0109 15.3941 21.211 15.3941C22.6695 15.3941 23.5373 14.6677 23.5373 13.1778V12.1721C22.9095 12.9729 21.9679 13.4944 20.6386 13.4944C18.0908 13.513 16.226 11.632 16.226 8.6707C16.226 5.74668 18.0908 3.847 20.6202 3.847C21.9864 3.847 22.9465 4.4616 23.5742 5.2997ZM21.211 11.3899C22.6326 11.3899 23.5188 10.291 23.5188 8.70795C23.5188 7.10626 22.6326 5.97017 21.211 5.97017C19.7893 5.97017 18.8847 7.08764 18.8847 8.68933C18.8847 10.291 19.7893 11.3899 21.211 11.3899Z"
        fill="white"
      />
      <path
        d="M33.3097 3.86562C35.4144 3.86562 37.1499 5.00171 37.1499 8.24234V13.5503H34.5467V8.46584C34.5467 6.90139 33.919 6.02605 32.6266 6.02605C31.2972 6.02605 30.5033 6.99451 30.5033 8.63346V13.5503H27.9001V0.513242H30.5033V5.1507C31.1126 4.40573 31.9619 3.86562 33.3097 3.86562Z"
        fill="white"
      />
      <path
        d="M39.4734 10.8125V6.19367H37.904V3.97737H39.4734V1.29546H42.0766V3.97737H44.2552V6.19367H42.0766V10.3841C42.0766 11.0546 42.3351 11.334 43.0182 11.334H44.3106V13.5503H42.1874C40.378 13.5503 39.4734 12.6377 39.4734 10.8125Z"
        fill="white"
      />
      <path
        d="M50.2981 13.5503V0.513242H55.56C58.4033 0.513242 60.3049 2.20806 60.3049 4.85271C60.3049 6.5289 59.4372 7.86986 58.0894 8.52171L60.6373 13.5503H57.5725L55.3939 9.17356H53.0676V13.5503H50.2981ZM55.3385 2.84128H53.0676V6.84552H55.3385C56.6493 6.84552 57.4802 6.0633 57.4802 4.85271C57.4802 3.62351 56.6493 2.84128 55.3385 2.84128Z"
        fill="white"
      />
      <path
        d="M66.1435 13.662C62.9864 13.662 61.0847 11.7437 61.0847 8.78245C61.0847 5.80255 63.0233 3.86562 65.9589 3.86562C68.8021 3.86562 70.7223 5.65356 70.7592 8.46584C70.7592 8.7452 70.7407 9.06181 70.6853 9.3598H63.7987V9.49017C63.8541 10.8498 64.7219 11.6692 66.0327 11.6692C67.0851 11.6692 67.8236 11.2036 68.0452 10.3096H70.6115C70.3161 12.1721 68.6729 13.662 66.1435 13.662ZM63.8541 7.64637H68.1375C67.9528 6.47303 67.159 5.82118 65.9773 5.82118C64.8511 5.82118 64.0018 6.51028 63.8541 7.64637Z"
        fill="white"
      />
      <path
        d="M71.6666 10.3655H74.1406C74.2329 11.2036 74.953 11.781 76.2269 11.781C77.3162 11.781 77.9809 11.4271 77.9809 10.7566C77.9809 9.97441 77.3347 9.91853 75.8023 9.71367C73.5867 9.45292 71.8697 8.95007 71.8697 6.97589C71.8697 5.07621 73.5129 3.82838 75.95 3.847C78.4978 3.847 80.2518 4.98308 80.381 6.93864H77.8885C77.8147 6.17504 77.0946 5.70943 76.0423 5.70943C75.0268 5.70943 74.3622 6.08192 74.3622 6.69652C74.3622 7.42287 75.1745 7.49737 76.5408 7.66499C78.7194 7.88848 80.5287 8.39134 80.5287 10.5518C80.5287 12.4514 78.7563 13.662 76.2084 13.662C73.5683 13.662 71.7774 12.4142 71.6666 10.3655Z"
        fill="white"
      />
      <path
        d="M87.3465 3.86562C89.9128 3.847 91.7591 5.76531 91.7591 8.78245C91.7591 11.7623 89.9128 13.662 87.365 13.662C86.0541 13.662 85.1125 13.1219 84.5033 12.3397V17.2751H81.9V3.97737H84.1709L84.4294 5.2997C85.0387 4.48023 85.9987 3.86562 87.3465 3.86562ZM86.7742 11.5202C88.1958 11.5202 89.1189 10.3841 89.1189 8.7452C89.1189 7.12488 88.1958 6.00742 86.7742 6.00742C85.3525 6.00742 84.4663 7.12488 84.4663 8.7452C84.4663 10.3841 85.3525 11.5202 86.7742 11.5202Z"
        fill="white"
      />
      <path
        d="M97.839 13.662C94.8111 13.662 92.8356 11.7065 92.8356 8.76383C92.8356 5.82118 94.8111 3.86562 97.839 3.86562C100.867 3.86562 102.842 5.82118 102.842 8.76383C102.842 11.7251 100.867 13.662 97.839 13.662ZM97.839 11.5202C99.2975 11.5202 100.202 10.3841 100.202 8.76383C100.202 7.14351 99.2975 6.00742 97.839 6.00742C96.3804 6.00742 95.4942 7.14351 95.4942 8.76383C95.4942 10.3841 96.3804 11.5202 97.839 11.5202Z"
        fill="white"
      />
      <path
        d="M109.722 3.86562C111.771 3.86562 113.525 4.96446 113.525 8.24234V13.5503H110.922V8.50308C110.922 6.88277 110.313 6.02605 109.002 6.02605C107.654 6.02605 106.879 6.99451 106.879 8.65208V13.5503H104.275V3.97737H106.546L106.786 5.16933C107.395 4.42435 108.263 3.86562 109.722 3.86562Z"
        fill="white"
      />
      <path
        d="M114.722 10.3655H117.196C117.289 11.2036 118.009 11.781 119.283 11.781C120.372 11.781 121.037 11.4271 121.037 10.7566C121.037 9.97441 120.39 9.91853 118.858 9.71367C116.642 9.45292 114.925 8.95007 114.925 6.97589C114.925 5.07621 116.569 3.82838 119.006 3.847C121.554 3.847 123.307 4.98308 123.437 6.93864H120.944C120.87 6.17504 120.15 5.70943 119.098 5.70943C118.083 5.70943 117.418 6.08192 117.418 6.69652C117.418 7.42287 118.23 7.49737 119.596 7.66499C121.775 7.88848 123.584 8.39134 123.584 10.5518C123.584 12.4514 121.812 13.662 119.264 13.662C116.624 13.662 114.833 12.4142 114.722 10.3655Z"
        fill="white"
      />
      <path
        d="M129.645 13.662C126.488 13.662 124.586 11.7437 124.586 8.78245C124.586 5.80255 126.525 3.86562 129.461 3.86562C132.304 3.86562 134.224 5.65356 134.261 8.46584C134.261 8.7452 134.242 9.06181 134.187 9.3598H127.3V9.49017C127.356 10.8498 128.224 11.6692 129.534 11.6692C130.587 11.6692 131.325 11.2036 131.547 10.3096H134.113C133.818 12.1721 132.175 13.662 129.645 13.662ZM127.356 7.64637H131.639C131.455 6.47303 130.661 5.82118 129.479 5.82118C128.353 5.82118 127.504 6.51028 127.356 7.64637Z"
        fill="white"
      />
    </svg>

    <svg
      className="group-1"
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18.241"
        cy="39.8142"
        r="4.90334"
        transform="rotate(-45 18.241 39.8142)"
        fill="white"
      />
      <circle
        cx="39.9422"
        cy="18.1137"
        r="4.90334"
        transform="rotate(-45 39.9422 18.1137)"
        fill="white"
      />
      <rect
        x="10.2461"
        y="33.5325"
        width="2.5382"
        height="6.23012"
        transform="rotate(-45 10.2461 33.5325)"
        fill="white"
      />
      <rect
        x="25.502"
        y="30.6771"
        width="2.5382"
        height="10.2682"
        transform="rotate(45 25.502 30.6771)"
        fill="white"
      />
      <rect
        x="37.4131"
        y="18.7664"
        width="2.5382"
        height="10.2682"
        transform="rotate(45 37.4131 18.7664)"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5109 32.7574C13.4258 30.8425 13.4258 27.7379 11.5109 25.823C9.59605 23.9082 6.49143 23.9082 4.57656 25.823C2.66169 27.7379 2.66169 30.8425 4.57656 32.7574C6.49143 34.6723 9.59605 34.6723 11.5109 32.7574ZM9.79775 31.0442C10.7665 30.0755 10.7665 28.5049 9.79775 27.5362C8.82905 26.5675 7.25848 26.5675 6.28978 27.5362C5.32108 28.5049 5.32108 30.0755 6.28978 31.0442C7.25848 32.0129 8.82905 32.0129 9.79775 31.0442Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.3957 32.5943C34.3106 30.6794 34.3106 27.5748 32.3957 25.6599C30.4808 23.745 27.3762 23.745 25.4613 25.6599C23.5465 27.5748 23.5465 30.6794 25.4613 32.5943C27.3762 34.5091 30.4808 34.5091 32.3957 32.5943ZM30.6825 30.8811C31.6512 29.9124 31.6512 28.3418 30.6825 27.3731C29.7138 26.4044 28.1432 26.4044 27.1745 27.3731C26.2058 28.3418 26.2058 29.9124 27.1745 30.8811C28.1432 31.8498 29.7138 31.8498 30.6825 30.8811Z"
        fill="white"
      />
    </svg>
  </div>
 
  {_.includes(user?.["rightresponse.ai/roles"]?.roles, "IRUser") && (
        <Button
          size='small'
          style={{ color: '#fff', display: 'flex', gap: 0.5, alignItems: 'center', textTransform: 'none', cursor: 'pointer',zIndex:50 }}
          onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
        >
          Logout{' '}<LogoutIcon style={{ fill: '#fff' }} />
        </Button>
      ) }
       
    </div>
  )
}

export default MobileHeader