import React, { useEffect, useState, useMemo } from 'react'
import PageLayout from '../components/common/pageLayout'
import { Stack, Button } from "@mui/material";
import _ from 'lodash';

import Header from '../pages/home/header';
import Stepper from '../pages/home/stepper';
import Footer from '../pages/home/footer';
import { useDispatch, useSelector } from 'react-redux';
import { updateSubscriptionInCompany, stripeSelector } from "../../store/slices/stripe";
const Home = ({page}) => {
  const dispatch = useDispatch();
  const { companyUpdated, loading } = useSelector(stripeSelector);
  const href = window.location.href;
  const params = _.split(href ? href : '', '?');
  // const [customer, setCustomer] = React.useState(null);
  // var customer = _.split(params?.[1], '=')?.[1];
  const customer = useMemo(() => { return (params?.[1]) }, [params])
  useState(() => {
    if (customer && page === 3) {
      dispatch(updateSubscriptionInCompany(customer));
    }
  }, customer)

  return (
    <>
      <div className='main-container'>
        <div className='sidebar'>
          <Stepper page={page} />
          <Footer />
        </div>

        <div className={'main-area'} style={{ position: 'relative' }}>
          <>{companyUpdated && !loading && page === 3 &&
            <Header page={3} title={"You’re all set!"} subtitle={'We have sent a verification email to you. Please check your email and verify.'} />

          }
            { page === 1 &&
              <Header page={1} title={"Your tansaction has been cancelled !"} subtitle={'You can contact us at support@rightresponseai.com.'} />
            }
            { page === 2 &&
              <Header page={1} title={"Something went wrong."} subtitle={'You can contact us at support@rightresponseai.com.'} />
            }
          </>
        </div>

      </div>

    </>
  )
}

export default Home