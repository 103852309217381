import React from 'react'

const MobileFooter = () => {
  return (
    <div className='footer' style={{position:'absolute',bottom:0,left:0,width:'100%'}}>
    <div className="text">© RightResponse AI</div>
        <div className="row">
          <svg
            className="mail-01"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.33301 4.66666L6.77629 8.47695C7.21707 8.7855 7.43746 8.93977 7.67718 8.99953C7.88894 9.05231 8.11041 9.05231 8.32217 8.99953C8.56189 8.93977 8.78228 8.7855 9.22306 8.47695L14.6663 4.66666M4.53301 13.3333H11.4663C12.5864 13.3333 13.1465 13.3333 13.5743 13.1153C13.9506 12.9236 14.2566 12.6176 14.4484 12.2413C14.6663 11.8135 14.6663 11.2534 14.6663 10.1333V5.86666C14.6663 4.74655 14.6663 4.1865 14.4484 3.75868C14.2566 3.38235 13.9506 3.07639 13.5743 2.88464C13.1465 2.66666 12.5864 2.66666 11.4663 2.66666H4.53301C3.4129 2.66666 2.85285 2.66666 2.42503 2.88464C2.0487 3.07639 1.74274 3.38235 1.55099 3.75868C1.33301 4.1865 1.33301 4.74655 1.33301 5.86666V10.1333C1.33301 11.2534 1.33301 11.8135 1.55099 12.2413C1.74274 12.6176 2.0487 12.9236 2.42503 13.1153C2.85285 13.3333 3.4129 13.3333 4.53301 13.3333Z"
              stroke="#E9D7FE"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <div className="help-rightresponse-com">help@rightresponseai.com</div>
        </div>
        </div>
  
  )
}

export default MobileFooter