import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const Auth0ProviderWithNavigate = ({ children }) => {
    const navigate = useNavigate();

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
    const scope = process.env.REACT_APP_AUTH0_SCOPE

    const onRedirectCallback = (appState) => {

        navigate(history.replace({
            pathname: appState?.returnTo || window.location.pathname,
            search: '',
        }));
    };

    if (!(domain && clientId && redirectUri)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            useRefreshTokens={true}
            authorizationParams={{
                redirect_uri: window.location.origin,
                screen_hint: 'signup',
                scope: scope
            }}
            onRedirectCallback={onRedirectCallback}
            cacheLocation="localstorage"
        >
            {children}
        </Auth0Provider>
    );
};
