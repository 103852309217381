import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import Header from '../../pages/home/header';
import Stepper from '../../pages/home/stepper';
import Footer from '../../pages/home/footer';
import SignupRequest from './signupRequest';
import { validateSignupRequest, signupRequestSelector } from './../../../store/slices/signupRequest'
import { useDispatch, useSelector } from 'react-redux';
import AppConfig from '../../../appConfig';
import { getRedirectUrl } from '../../../common/utils';
import useMobileOrTablet from '../../../common/detectMobile';
import TitleHelper from '../../components/common/titleHelper';
import MobileHeader from '../../components/common/mobileHeader';
import MobileFooter from '../../components/common/mobileFooter';
const Home = ({ page, setPage, queryString }) => {
    const dispatch = useDispatch();
    const { clickStatus, requestData } = useSelector(signupRequestSelector);
    const isMobile = useMobileOrTablet()
    useEffect(() => {
        if (queryString && !_.includes(queryString, '#')) {
            dispatch(validateSignupRequest(queryString));
        }

    }, [queryString]);


    const getLink = () => {
        return (<><div style={{ marginTop: '30px' }}>
            <span >
                <a className='data-link' onClick={() => window.location.href = getRedirectUrl(AppConfig.appMode) + '#/?companyId=' + requestData?.companyId + '&userId=' + requestData?.userId}>Click here</a>
            </span> to login in your existing subscription.
        </div>
        </>
        )
    }
    const HEADER = useMemo(() => isMobile ? TitleHelper : Header, [isMobile])
    return (
        <div className='main-container'>
            {!isMobile && <div className='sidebar'>
                <Stepper page={4} />
                <Footer />
            </div>}
            {isMobile && <MobileHeader />}
            <div className={isMobile ? "mobile" : 'main-area'} style={{ position: 'relative' }}>
                <>
                    {page === 1 && clickStatus === "Valid" && requestData &&
                        <div>
                            <HEADER page={page} title={''} subtitle={''} />
                            <div style={{ marginTop: isMobile ? '-50px' : '0px' }}>
                                <SignupRequest setPage={setPage} userDetails={requestData} /></div>
                        </div>
                    }
                    {page === 1 && clickStatus === "AlreadyExist" && requestData &&
                        <HEADER page={4} title={"You already have a subscription."}
                            subtitle={getLink()} />
                    }
                    {page === 1 && clickStatus === "LinkExpired" &&
                        <HEADER page={1} title={"Your Signup Request has expired."}
                            subtitle={'You can contact us at support@rightresponseai.com.'} />
                    }
                    {page === 1 && clickStatus === "InvalidCode" &&
                        <HEADER page={1} title={"Invalid Signup Request."}
                            subtitle={'You can contact us at support@rightresponseai.com.'} />
                    }
                    {page === 2 &&
                        <HEADER page={1} title={"Something went wrong."}
                            subtitle={'You can contact us at support@rightresponseai.com.'} />
                    }
                </>
                {isMobile && <MobileFooter />}
            </div>
        </div>
    )
}

export default Home
