import React from 'react';
import { Dialog, Box, Divider, Stack, Typography, TextField, useTheme, DialogTitle, Checkbox, DialogContent, DialogActions } from '@mui/material'

const WarningModal = ({ open, width, children, sx }) => {


    return (
        <Dialog
            open={open}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <DialogTitle>
                <Typography variant="subtitle1">Alert</Typography>
            </DialogTitle>
            <Divider style={{ margin: 0 }} />
            <Box sx={{ px: 2, width: width ? width : 400, ...sx, }}>
                {children}
            </Box>
        </Dialog>
    )
}
export default WarningModal;