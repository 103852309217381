import _ from 'lodash';

export const getAuthToken = () => {
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const authTokenStorage = localStorage.getItem(`@@auth0spajs@@::${clientId}::@@user@@`);
    return JSON.parse(authTokenStorage)?.id_token;
}

export const isEmpty = (value) => {
    return _.isEmpty(value);
};

export const isEmpty2 = function (value, allowEmptyString) {
    return (value === ' ') || (value === null) || (value === undefined) || (!allowEmptyString ? value === '' : false) || (isArray(value) && value.length === 0);
};
export const isArray = ('isArray' in Array) ? Array.isArray : function (value) {
    return toString.call(value) === '[object Array]';
};
export const getUserId = () => {
    return localStorage.getItem('userId') ? localStorage.getItem('userId') : null;
}
export const convertArrayToDictionary = (array, id) => {
    var obj = {};
    _.map(array, (item) => {
        if (id) {
            obj[item[id]] = item;
        } else {
            obj[item] = item;
        }
        return item;
    })
    return obj;
};

export const getRedirectUrl = (env) => {

    return  env=="prod"? "https://app.rightresponse.ai/":`https://${env}.rightresponse.ai/`
}
