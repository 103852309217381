import React, { useEffect,useMemo } from 'react' 
import _ from 'lodash';
import Customer from './customer';
import Business from './business';
import Welcome from './welcome';
import Header from './header';
import Stepper from './stepper';
import Footer from './footer';
import { useForm } from "react-hook-form";
import useMobileOrTablet from '../../../common/detectMobile';
import TitleHelper from '../../components/common/titleHelper';
import MobileHeader from '../../components/common/mobileHeader';
import MobileFooter from '../../components/common/mobileFooter';
import ManageProduct from '../manageProduct';

const Home = ({page, setPage, signUpUser, existingUser , selectedProduct={selectedProduct}}) => {
    const isMobile=useMobileOrTablet()
    const HEADER=useMemo(()=>isMobile?TitleHelper:Header,[isMobile])
    return (<>
        {existingUser  &&
        
        <div className='main-container'>
         {!isMobile&&<div className='sidebar'>
                <Stepper page={page} />
                <Footer />
            </div>}
            {isMobile&&<MobileHeader/>}
            <div className={isMobile?"mobile":'main-area'} style={{position:'relative'}}>
                {page === 1 && signUpUser?.signUpId !=null &&
                    <div>
                        <HEADER page={page} title={'Let’s get to know each other'} subtitle={'Please tell us more about yourself'} />
                        <div style={{marginTop:isMobile?'-300px':'0px'}}>
                        <Customer setPage={setPage} signUpId={signUpUser.signUpId} 
                        userDetails={signUpUser} existingUser={existingUser}/>
                        </div>
                    </div>
                }
                 {page === 2 &&
                    <div>
                        <HEADER setPage={setPage} page={page} title={'Tell us more about your business'} subtitle={'Please complete the short survey to help us understand your business'} />
                        <div style={{marginTop:isMobile?'-340px':'0px',position:isMobile?'relative':''}}>
                        <Business setPage={setPage} signUpId={signUpUser.signUpId}
                         userDetails={signUpUser}  existingUser={existingUser} page={page}/>
                         </div>
                    </div>
                }
                {page === 3 &&
                    <>
                        <HEADER page={page} title={"You’re all set!"} subtitle={'We have sent a verification email to you. Please check your email and verify.'} />
                        <Welcome setPage={setPage} signUpId={signUpUser.signUpId} companyId={signUpUser?.companyId} userId={signUpUser?.userId} />
                    </>
                }
                {page === 4 &&
                    <>
                        <ManageProduct selectedProduct={selectedProduct} />
                    </>
                }
                 {isMobile&&<MobileFooter/>}
            </div>
        
    </div>
    }
    </> 
    )
     
}

export default Home