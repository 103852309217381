import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import AppConfig from "../../appConfig";
import { getAuthToken } from "../../common/utils";
import { createSignup, usersSelector } from "../../store/slices/users";
import Errors from '../pages/errors/unauthorized';
import Home from '../pages/home/index';
import NewCompany from '../pages/home/newCompany';
import NewUser from '../pages/newUser';
import Thankyou from '../pages/thankyou';
import ManageProduct from '../pages/manageProduct';
import SignupRequest from '../pages/signupRequest/index';
import Loader from "./spinners/loader";
import _ from 'lodash';

const App = (props) => {
    const appMode = AppConfig.appMode;
    const byPassAuth0 = props.byPassAuth0;
    const { isLoading, isAuthenticated, user, loginWithRedirect } = useAuth0();
    const authToken = getAuthToken();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const [page, setPage] = React.useState(1);
    const { signUpUser, existingUser, companyDeleted ,hasSubscription} = useSelector(usersSelector);
    const selectedProduct =  localStorage.getItem('buynow') ;

    useEffect(() => {
        if (!authToken && isAuthenticated && !byPassAuth0) {
            navigate('/error/unauthorized', { replace: true })
        }
    }, [authToken, location.pathname])

    useEffect(() => {
        if (user?.email) {
            dispatch(createSignup({ Auth0UserId: user.sub, Email: user.email }))
        }
    }, [user])
    if (isLoading && !byPassAuth0) {
        return <Loader />;
    }
    if (!isAuthenticated && !byPassAuth0) {
        let baseUrl = appMode == "local" ? "https://localhost:3000/" : AppConfig.apiUrl
        let isbuyNow = !_.isEmpty(localStorage.getItem('buynow'));
        let callbackUrl = baseUrl + (isbuyNow ? "#/buymrtnow" : "#/SignUp");
        loginWithRedirect({ authorizationParams: { redirect_uri: callbackUrl } });
    }

    if (!authToken && !byPassAuth0) {
        return (<Routes>
            <Route path="/error/unauthorized" element={<Errors />} />
        </Routes>)
    }
    return (

        <div>
            {(isAuthenticated && !byPassAuth0) &&
                <Routes>
                    <Route path="/" element={<Navigate to={appMode === "local" ? "/SignUp" : "/"} replace={true} />} />
                    <Route path="/signup" element={<Home setPage={setPage} page={page} existingUser={existingUser}
                        signUpUser={signUpUser} companyDeleted={companyDeleted} hasSubscription={hasSubscription}/>} />
                    <Route path="/buymrtnow" element={<Home setPage={setPage} page={page} existingUser={existingUser}
                        signUpUser={signUpUser} companyDeleted={companyDeleted} selectedProduct={selectedProduct} hasSubscription={hasSubscription}/>} />
                    <Route path="/company" element={<NewCompany setPage={setPage} page={page}
                        signUpUser={signUpUser} existingUser={existingUser}  selectedProduct={selectedProduct}/>
                    } />
                    <Route path="/success" element={<Thankyou page={3} />} />
                    <Route path="/cancel" element={<Thankyou page={1} />} />
                    <Route path="/error" element={<Thankyou page={2} />} />
                </Routes>
            }
            {(!isAuthenticated && byPassAuth0) &&
                <Routes>
                    
                    <Route path="/signupRequest" element={<SignupRequest setPage={setPage} page={page}  queryString={props.queryString} />} />
                    <Route path="/NewUser" element={<NewUser setPage={setPage} page={page} queryString={props.queryString} />} />
                </Routes>
            }
        </div>
    )
}

export default App
