import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Root from './ui/components/root';
import { HashRouter } from "react-router-dom";
import configureStore from './store/store';
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import queryString from 'query-string';
import _ from 'lodash';
import AppConfig from './appConfig';

const root = ReactDOM.createRoot(document.getElementById('root'));
const href = window.location.href;
const params = _.split(href ? href : '', '?');
let parsed= "";
if(_.includes( href,'BuyMRTNow') || _.includes( href,'buymrtnow'))
{
    localStorage.setItem('buynow','mrt')
}
else{
    localStorage.setItem('buynow','')
}
if(AppConfig.appMode=="prod")
{
    if(_.isEmpty(localStorage.getItem('queryString')) )
    {
        localStorage.setItem('queryString', "AZnN35zs/7FOlfXelqTyO9Y9G7MFKpptF17AXkaavgX2TRgdxVno2bJd+9R6xOHG");
    }
}
if(params[1] )
{
    parsed = queryString.parse(params[1]);
    if(!parsed['code'])
    {
        localStorage.setItem('queryString', params[1]);
    }
}

const byPassAuth0 = _.includes(href, 'NewUser') || _.includes(href, 'signupRequest');

root.render(
    <HashRouter>
        {byPassAuth0 &&
            <Root store={configureStore()} byPassAuth0={byPassAuth0} queryString={params[1]} url={href}/>
        }
        {!byPassAuth0 &&
            <Auth0ProviderWithNavigate>
                <Root store={configureStore()} byPassAuth0={false} queryString={params[1]}  url={href}/>
            </Auth0ProviderWithNavigate>
        }
    </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

