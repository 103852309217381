import React, { useEffect } from 'react'
import PageLayout from '../../components/common/pageLayout'
import { Stack, Button, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { updateUser, createExistingUser } from "../../../store/slices/users";
import _ from 'lodash';
import { useForm } from "react-hook-form";

const Home = ({ setPage, userDetails, existingUser, companyDeleted }) => {


    const [isChange, setIsChange] = React.useState(false);
    const [accepted, setAccepted] = React.useState(false);

    const [firstName, setFirstName] = React.useState(userDetails.firstName);
    const [lastName, setLastName] = React.useState(userDetails.lastName);
    const [companyName, setCompanyName] = React.useState((existingUser && userDetails.companyId) ? "" : userDetails.companyName);
    const dispatch = useDispatch();
    const { handleSubmit, reset, formState: { errors } } = useForm({ mode: "all" });


    const onContinue = () => {
        if (accepted) {
            let data = {
                firstName: _.trim(firstName),
                lastName: _.trim(lastName),
                companyName: _.trim(companyName),
                signUpId: userDetails?.signUpId
            }
            if (isChange) {
                if (existingUser) {
                    dispatch(createExistingUser(data))
                }
                else {
                    dispatch(updateUser(data))
                }
            }
            setPage(2)
        }
    }

    return (
        <div style={{ marginTop: '300px', width: '385px', overflow: 'hidden' }}>
            <PageLayout title=''>
                <form onSubmit={() => handleSubmit(onContinue())}>
                    <Stack justifyContent={"center"} direction={"column"} alignItems={"center"} spacing={2}>
                        <TextField required
                            id="txtFirstName"
                            value={firstName}
                            label="First Name"
                            variant="outlined"
                            size="small"
                            disabled={existingUser || companyDeleted}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { setFirstName(e.target.value.trimStart()); setIsChange(true) }}
                        />
                        <TextField required
                            id="txtLastName"
                            value={lastName}
                            label="Last Name"
                            variant="outlined"
                            size="small"
                            disabled={existingUser || companyDeleted}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { setLastName(e.target.value.trimStart()); setIsChange(true) }}
                        />
                        <TextField required
                            id="txtCompanyName"
                            value={companyName}
                            label="Company Name"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { setCompanyName(e.target.value.trimStart()); setIsChange(true) }}
                        />
                        <div style={{ display: 'flex', width: '385px' }}>
                            <input checked={accepted} style={{ zIndex: 1000, height: '20px', width: '20px!important', marginRight: '4px' }} type='checkbox' onChange={(e) => setAccepted(e.target.checked)} />
                            <span className='accept-terms' style={{ display: 'flex', flexDirection: 'column' }}>{'I acknowledge that I have reviewed and accept the '}
                                <span style={{ textAlign: 'left' }}><a href='https://static.rightresponse.ai/agreements/TOS-20240101.pdf' target='_blank'>Terms of Services</a>
                                    <span>{', '}<a href='https://static.rightresponse.ai/agreements/EULA-20240101.pdf' target='_blank'> {'End User License Agreement'}</a> </span>
                                </span>
                                <span>{' and '} <a href='https://static.rightresponse.ai/agreements/PP-20240101.pdf' target='_blank'> {'Privacy Policy.'}</a></span>
                            </span>
                        </div>

                        <Button type="submit" disabled={!accepted} className="button" sx={{ textTransform: 'none', fontSize: '16px' }} >Continue</Button>
                    </Stack>
                </form>
            </PageLayout>
        </div>
    )
}

export default Home
