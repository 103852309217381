import { createSlice } from "@reduxjs/toolkit"
import { getAxios ,postAxios} from "./axiosActions"
import _ from 'lodash'; 

const initialState = { 
    loading:true,
    checkoutUrl:"",
    companyUpdated:false,
    stripeProducts:[],
    priceList:[],
    signupData:[],
    couponDetail:null
}



const stripeSlice = createSlice({
    name: "stripe",
    initialState,
    reducers: {  
        getPaymentLinkSuccess(state, { payload }) {
            state.checkoutUrl = payload
        }, 
        updateCompanySuccess(state, { payload }) {
            state.companyUpdated = payload; 
            state.loading = false
        }, 
        getProductsSuccess(state, { payload }) {
            state.stripeProducts = payload;  
        }, 
        getPriceListSuccess(state, { payload }) {
            state.priceList = payload;  
        }, 
        setSignupDataSuccess(state, { payload }) {
            state.signupData = payload;  
        }, 
        getCouponDetailSuccess(state, { payload }) {
            state.couponDetail = payload;  
        },
        
    }
})

export default stripeSlice.reducer
export const stripeSelector = state => state.stripe;
export const { getPaymentLinkSuccess,updateCompanySuccess,getPriceListSuccess,getProductsSuccess,
    setSignupDataSuccess,getCouponDetailSuccess
} = stripeSlice.actions

 
 
export function getPaymentLink(data) {
    return async (dispatch) => { 
        try { 
            const url = 'api/Stripe/GetPaymentLink';
            const response = await postAxios(url, { params: data });
            if (response) {
                dispatch(getPaymentLinkSuccess(response));
            } else { 
            }

        } catch (error) { 
        }
    };
} 

export function getProducts() {
    return async (dispatch) => { 
        try { 
            const url = 'api/Stripe/GetStripeProducts';
            const response = await getAxios(url, { params: {} });
            if (response) {
                dispatch(getProductsSuccess(response));
            } else { 
            }

        } catch (error) { 
        }
    };
}

export function getPriceList() {
    return async (dispatch) => { 
        try { 
            const url = 'api/Stripe/GetPriceList';
            const response = await getAxios(url, { params: {} });
            if (response) {
                dispatch(getPriceListSuccess(response));
            } else { 
            }

        } catch (error) { 
        }
    };
}

export function updateSubscriptionInCompany(customerId) {
    return async (dispatch) => { 
        try { 
            const url = 'api/Company/UpdateSubscriptionInCompany';
            const response = await postAxios(url, { params: {customerId:customerId} });
            if (response) {
                dispatch(updateCompanySuccess(response));
            } else { 
            }

        } catch (error) { 
        }
    };
}
export const setSignupData = (val) => {
    return async dispatch => { 
        try { 
            dispatch(setSignupDataSuccess(val));
        } catch (error) {
            
        }
    }
};

export function getCouponDetail(code) {
    return async (dispatch) => { 
        try { 
            const url = 'api/Stripe/GetCouponDetail?couponCode='+code;
            const response = await getAxios(url, { params: {} });
            if (response) {
                dispatch(getCouponDetailSuccess(response));
            } else { 
            }

        } catch (error) { 
        }
    };
}