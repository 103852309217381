import React, { useEffect } from 'react'
import PageLayout from '../../components/common/pageLayout'
import { Stack, Button, FormControl, InputLabel, Select, MenuItem, TextField, Hidden } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { updateBusiness, updateExistingUser, usersSelector } from "../../../store/slices/users";
import { setSignupData, stripeSelector } from "../../../store/slices/stripe";
import _ from 'lodash';
import useMobileOrTablet from '../../../common/detectMobile';

const Home = ({ setPage, userDetails, existingUser,page }) => {
    const isMobile = useMobileOrTablet()
    const [industry, setIndustry] = React.useState(existingUser ? "" : userDetails.industry);
    const [locations, setLocations] = React.useState(existingUser ? 0 : userDetails.locations);
    const [source, setSource] = React.useState(existingUser ? "" : userDetails.channelSource);
    const [otherSource, setOtherSource] = React.useState("");
   
    const dispatch = useDispatch();
    const { selectedProduct } = useSelector(usersSelector);
    const { checkoutUrl } = useSelector(stripeSelector);
    useEffect(() => {
        if (checkoutUrl && checkoutUrl != "error") {
            window.open(checkoutUrl, "_self");
        }

    }, [checkoutUrl]);
    const onContinue = () => {
        let data = {
            locations: locations,
            industry: industry,
            ChannelSource: source == "Other" ? "Other: " + _.trim(otherSource) : _.trim(source),
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            email: userDetails.email,
            signUpId: userDetails.signUpId,
            companyName: userDetails.companyName,
        }
        if (selectedProduct == "mrt") {
           dispatch(setSignupData(data)) ;
            setPage(4)
        }
        else {
            if (existingUser) {
                dispatch(updateExistingUser(data))
            }
            else {
                dispatch(updateBusiness(data))
            }
            setPage(3)
        }

    }
    return (
        <>
        {page !== 4 &&
            <div style={{ marginTop: '370px', overflow: 'hidden', }}>
            <PageLayout title='' sx={{ position: isMobile ? 'relative' : '' }}>
                <Button className='skip_survey' sx={{ position: 'absolute', top: isMobile ? -20 : 40, right: isMobile ? 24 : 48 }} onClick={() => onContinue()}>Skip survey</Button>
                <Stack justifyContent={"center"} direction={"column"} alignItems={"center"} sx={{ paddingTop: isMobile ? 2 : 0 }}>

                    <FormControl sx={{ m: 1, minWidth: 320 }} className="ddl_std">
                        <InputLabel sx={{ background: "white" }} id="dropdown-label" shrink={true}>
                            How many locations do you have?
                        </InputLabel>
                        <Select
                            id="select1"
                            value={locations}
                            label="Select"
                            onChange={(e) => setLocations(e.target.value)}
                        >
                            <MenuItem value={0} sx={{ height: '36px' }}></MenuItem>
                            <MenuItem value={1}>Single Location</MenuItem>
                            <MenuItem value={10}>2 - 10 Locations</MenuItem>
                            <MenuItem value={50}>11 - 50 Locations</MenuItem>
                            <MenuItem value={999}>More than 50 Locations</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 320 }} className="ddl_std">
                        <InputLabel sx={{ background: "white" }} id="dropdown-label" shrink={true}>
                            How did you hear about us?
                        </InputLabel>
                        <Select
                            id="select1"
                            value={source}
                            label="Select"
                            onChange={(e) => setSource(e.target.value)}
                        >
                            <MenuItem value={""} sx={{ height: '36px' }}></MenuItem>
                            <MenuItem value={"Online Search"}>Online Search</MenuItem>
                            <MenuItem value={"LinkedIn"}>LinkedIn</MenuItem>
                            <MenuItem value={"Article"}>Article</MenuItem>
                            <MenuItem value={"Referral"}>Referral</MenuItem>
                            <MenuItem value={"Other"}>Other</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 320 }} className="ddl_std">
                        <>
                            {source == "Other" &&
                                <TextField
                                    id="txtOther"
                                    value={otherSource}
                                    label="Please specify"
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => { setOtherSource(e.target.value.trimStart()) }}
                                />
                            }
                        </>

                    </FormControl>
                    <Button sx={{ margin: '8px', textTransform: 'none', fontSize: '16px' }} className="button" onClick={() => onContinue()}>Continue</Button>
                </Stack>
            </PageLayout>
        </div>
        }
        
        </>
    )
}

export default Home
