import React from 'react'
import { usersSelector } from '../../../store/slices/users';
import { useSelector } from 'react-redux';

const TitleHelper = ({ page, title, subtitle }) => {
    const { userInfo, auth0UserStatus } = useSelector(usersSelector);
    return (
        <div style={{paddingTop:'64px',position:'relative',marginTop:40}}>
            {page !== 3 &&
                <div style={{ position: 'absolute', left: 'calc(50% - 24px)', top: 120 }}>

                    <svg
                        className="group-1"
                        width="46"
                        height="46"
                        viewBox="0 0 46 46"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="17.3299"
                            cy="38.9956"
                            r="4.90334"
                            transform="rotate(-45 17.3299 38.9956)"
                            fill="#3681EB"
                        />
                        <circle
                            cx="39.031"
                            cy="17.2951"
                            r="4.90334"
                            transform="rotate(-45 39.031 17.2951)"
                            fill="#3681EB"
                        />
                        <rect
                            x="9.33496"
                            y="32.7139"
                            width="2.5382"
                            height="6.23012"
                            transform="rotate(-45 9.33496 32.7139)"
                            fill="#3681EB"
                        />
                        <rect
                            x="24.5908"
                            y="29.8586"
                            width="2.5382"
                            height="10.2682"
                            transform="rotate(45 24.5908 29.8586)"
                            fill="#3681EB"
                        />
                        <rect
                            x="36.502"
                            y="17.9478"
                            width="2.5382"
                            height="10.2682"
                            transform="rotate(45 36.502 17.9478)"
                            fill="#3681EB"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.5998 31.9389C12.5147 30.024 12.5147 26.9194 10.5998 25.0045C8.68492 23.0896 5.5803 23.0896 3.66543 25.0045C1.75055 26.9194 1.75055 30.024 3.66543 31.9389C5.5803 33.8537 8.68492 33.8537 10.5998 31.9389ZM8.88662 30.2257C9.85532 29.257 9.85532 27.6864 8.88662 26.7177C7.91792 25.749 6.34735 25.749 5.37865 26.7177C4.40995 27.6864 4.40995 29.257 5.37865 30.2257C6.34735 31.1944 7.91792 31.1944 8.88662 30.2257Z"
                            fill="#3681EB"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M31.4846 31.7757C33.3994 29.8608 33.3994 26.7562 31.4846 24.8413C29.5697 22.9265 26.4651 22.9265 24.5502 24.8413C22.6353 26.7562 22.6353 29.8608 24.5502 31.7757C26.4651 33.6906 29.5697 33.6906 31.4846 31.7757ZM29.7714 30.0625C30.7401 29.0938 30.7401 27.5233 29.7714 26.5546C28.8027 25.5859 27.2321 25.5859 26.2634 26.5546C25.2947 27.5233 25.2947 29.0938 26.2634 30.0625C27.2321 31.0312 28.8027 31.0312 29.7714 30.0625Z"
                            fill="#3681EB"
                        />
                    </svg>
                </div>
            }
            {page === 3 &&
                <div style={{ position: 'absolute', left: 'calc(50% - 20px)', top: 100 }}>
                    {/* <div className="text">🎉</div> */}
                    <img src={process.env.PUBLIC_URL + '/images/finish.png'} alt="" />
                </div>
            }
            <div style={{ marginTop: '65px', width: '385px' }}>

                {title&&<div className="text">{title}</div>}
                {subtitle&&<div className="supporting-text">{subtitle}</div>}

            </div>
        </div>
    )
}

export default TitleHelper