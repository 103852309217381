import { combineReducers } from '@reduxjs/toolkit'; 
import users from './slices/users'; 
import stripe from './slices/stripe';
import signupRequest from './slices/signupRequest';

const allReducers = combineReducers({ 
    users:users, 
    stripe:stripe,
    signupRequest:signupRequest
});
const rootReducer = (state, action) => {
    return allReducers(state, action);
}
export default rootReducer;