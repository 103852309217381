const getBaseUrl = () => {
    return '/';
};

const getAppMode = () => {
    const hostName = window?.location?.hostname;

    if (hostName?.toLowerCase().includes('localhost')) {
        return 'local';
    } 
    else if (hostName?.toLowerCase().includes('devaccount.rightresponse.ai')) {
        return 'dev';
    } else if (hostName?.toLowerCase().includes('testaccount.rightresponse.ai')) {
        return 'test';
    } else if (hostName?.toLowerCase().includes('demoaccount.rightresponse.ai')) {
        return 'demo';
    } else if (hostName?.toLowerCase().includes('account.rightresponse.ai')) {
        return 'prod';
    } 
    else {
        return 'local';
    }
};

const getSignalRAutoRefreshUrl = () => {
    const appMode = getAppMode();
    if (appMode === 'dev') return "https://devaccount.rightresponse.ai/SignalRHub/";
    if (appMode === 'test') return "https://testaccount.rightresponse.ai/SignalRHub/";
    if (appMode === 'demo') return "https://demoaccount.rightresponse.ai/SignalRHub/";
    if (appMode === 'prod') return "https://account.rightresponse.ai/SignalRHub/";

    return "https://localhost:44370/SignalRHub/";
};
const getApiUrl = () => {
    var _apiUrl = 'https://localhost:44370/';
     //var _apiUrl = 'https://devaccount.rightresponse.ai/';
    var _isProdution = window.location.hostname === 'localhost' ? false : true;
    if (_isProdution) {
        _apiUrl = `https://${window.location.hostname}/`;
    }
    return _apiUrl;
};


const AppConfig = {
    appMode: getAppMode(), // local or dev or prod
    signalRAutoRefreshUrl: getSignalRAutoRefreshUrl(),
    env: function (config) {
        if (this.appMode === 'prod') {
            return this.prod[config];
        }
        else if (this.appMode === 'dev') {
            return this.dev[config];
        }
        else {
            return this.local[config];
        }
    },
    baseUrl: getBaseUrl(),
    apiUrl: getApiUrl(),
    reduxLogger: () => { return (getAppMode() === 'prod' ? false : true); },
    axiosLogger: () => { return (getAppMode() === 'prod' ? false : true); },
    local: { connectionId: '' },
    dev: { connectionId: '' },
    test: { connectionId: '' },
    prod: { connectionId: '' },
}

export default AppConfig;
