import { Typography } from '@mui/material'
import React from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

const Header = ({children,title,sx={}}) => {
  return (
  <Stack spacing={2}>
    <Typography variant='h4'>{title}</Typography>
    <Box sx={{...sx}}>{children}</Box>
    
    </Stack>
  )
}

export default Header