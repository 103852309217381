import React, { useEffect } from 'react'
import PageLayout from '../../components/common/pageLayout'
import { Stack, Button } from "@mui/material";
import AppConfig from '../../../appConfig';
  
const Home = ({ setPage, companyId, userId }) => {
   
  
  return (
    <>
      <div style={{ marginTop: '320px' }}>
        <PageLayout title=''>
          <Stack justifyContent={"center"} direction={"column"} alignItems={"center"} spacing={2}>
          </Stack>
        </PageLayout>
      </div>
    </>
  )
}

export default Home