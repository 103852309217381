import React from 'react';
import { Box, Typography } from "@mui/material"


const Index = () => {
    return (
        <Box sx={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='h3'>Unauthorized</Typography>
            <Typography variant='h6'>You don't have access to view this application.</Typography>


        </Box>
    )
}

export default Index;