import React from 'react'
import App from './App'
import { Provider } from 'react-redux'
import Spinner from './spinners/spinner';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from './common/notistack';
import _ from 'lodash';
import AppConfig from '../../appConfig'; 

const Root = ({ store, byPassAuth0, queryString ,url}) => {
     const apiUrl=AppConfig.apiUrl;
    if(url == apiUrl || url == apiUrl+'#/' )
    {
      return  window.location.replace('https://www.rightresponseai.com/');
    }
    else if(_.includes( url,'BuyMRTNow') || _.includes( url,'buymrtnow') || _.includes( url,'success')|| _.includes( url,'cancel')|| _.includes( url,'NewUser')|| _.includes( url,'signupRequest')){
        return (
            <Provider store={store}>
                <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                    <SnackbarUtilsConfigurator />
                    <App byPassAuth0={byPassAuth0} queryString={queryString} url={url}/>
                    <Spinner />
                </SnackbarProvider>
            </Provider>
        )
       
    }
    else{
        return  window.location.replace('https://calendly.com/rightresponse/rightresponse-ai-introduction');
    }
    
}

export default Root