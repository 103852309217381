import React, { useEffect , useMemo} from 'react';
import _ from 'lodash';
import Stepper from './stepper';
import Footer from './footer';
import Customer from './customer';
import Header from './header';
import {fetchUserInfo,usersSelector} from './../../../store/slices/users'
import { useDispatch, useSelector } from 'react-redux';
import AppConfig from '../../../appConfig';
import { getRedirectUrl } from '../../../common/utils';
import useMobileOrTablet from '../../../common/detectMobile';
import TitleHelper from '../../components/common/titleHelper';
import MobileHeader from '../../components/common/mobileHeader';
import MobileFooter from '../../components/common/mobileFooter';
const Home = ({ page, setPage, queryString }) => {
    const dispatch = useDispatch();
    const isMobile=useMobileOrTablet()
    useEffect(() => {
        if(queryString)
        {
          //  const params = _.split(queryString ? queryString : '', '?');
            dispatch(fetchUserInfo(queryString));
        }
          
    }, [queryString]);
    
    const { userInfo, auth0UserStatus } = useSelector(usersSelector);

    const status = useMemo(() => userInfo?.status, [userInfo?.status])
    const existingUser = useMemo(() => userInfo?.status, [userInfo?.status])
    
    const getLink = () => {
        return (<><div style={{ marginTop: '30px' }}>
            <span >
                <a className='data-link' onClick={() => window.location.href=getRedirectUrl(userInfo?.env??"dev")+'#/?companyId=' + userInfo?.companyId + '&userId=' + userInfo?.userId}>Click here</a>
            </span> to login in your subscription.
        </div>
            </>
        )
    }
    const HEADER=useMemo(()=>isMobile?TitleHelper:Header,[isMobile])
    return (
        <div className='main-container'>
        {!isMobile&&<div className='sidebar'>
                <Stepper page={page} />
                <Footer />
            </div>}
            {isMobile&&<MobileHeader/>}
            <div className={isMobile?"mobile":'main-area'} style={{position:'relative'}}>
                <>
                    {auth0UserStatus ==200 && page== 1 && 
                        <div>
                            <HEADER page={page} title={''} subtitle={''} />
                            <div style={{marginTop:isMobile?'-50px':'0px'}}>
                            <Customer setPage={setPage} userDetails={userInfo} existingUser={status === 1}  /></div>
                        </div>
                    }
                    {status === 1 && auth0UserStatus == 200 && page== 2 && 
                        <>
                            <HEADER page={page} title={"You are all set!"}
                                subtitle={getLink()} />
                        </>
                    }
                    {status === 3 && auth0UserStatus ==-101 &&
                        <>
                            <HEADER page={page} title={"User already exists"}
                                subtitle={getLink()} />
                        </>
                    }
                    {status === 3 && auth0UserStatus == 401 &&
                        <>
                            <HEADER page={page} title={'Please try again'} subtitle={''} />
                            <Customer setPage={setPage} userDetails={userInfo} existingUser={existingUser} />
                        </>
                    }
                </>
                {isMobile&&<MobileFooter/>}
        </div>
    </div>
    )
}

export default Home
