import { createSlice } from "@reduxjs/toolkit";
import { getAxios, postAxios } from "./axiosActions";
import { isEmpty2 } from '../../common/utils';
import { snackActions } from '../../ui/components/common/notistack';
import _ from 'lodash';

export const initialState = {
    loading: false,
    hasErrors: false,
    addUserErrors: false,
    existingUser: false,
    companyDeleted: false,
    hasSubscription: true,
    addUserMessage: '',
    signUpUser:{},
    users: [],
    userInfo: {},
    signupRequest: {},
    auth0UserStatus:100,
    selectedProduct:null,
    clickStatus :null
};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        getUsers: state => {
            state.loading = true;
        },
        getUsersSuccess: (state, { payload }) => {
            state.users = payload;
            state.loading = false;
            state.hasErrors = false;
        },
        getUsersFailure: state => {
            state.loading = false;
            state.hasErrors = true;
        },

        addUser: state => {
            state.loading = true
        },
        addUserSuccess: (state, { payload }) => { 
            state.signUpUser =payload.loggedInUser; 
            state.existingUser=payload.existingUser; 
            state.companyDeleted=payload.companyDeleted; 
            state.hasSubscription=payload.hasSubscription; 
            
            state.addUserErrors = false;
            state.loading = false
        },
        updateUserSuccess: (state, { payload }) => { 
            state.signUpUser =payload;  
            state.addUserErrors = false;
            state.loading = false
        },
        addUserFailure: (state, { payload }) => {
            state.loading = false
            state.addUserErrors = true
            if (!isEmpty2(payload?.statusCode)) {
                snackActions.error(payload?.value)
            }
        },
        getUserInfo: state => {
            state.loading = true;
        },
        getUserInfoSuccess: (state, { payload }) => {
            state.userInfo = payload;
            state.auth0UserStatus= 200;
            state.loading = false;
            state.hasErrors = false;
        },
        getUserInfoFailure: state => {
            state.loading = false;
            state.hasErrors = true;
        },
        createAuth0User: state => {
            state.loading = true
        },
        createAuth0UserSuccess: (state, { payload }) => {  
            state.userInfo['status']=payload.userstatus;
            state.auth0UserStatus=payload.status;
            state.loading = false
        },
        createAuth0UserFailure: (state, { payload }) => {
            state.loading = false
            state.hasErrors = true;
        },
        updateCompanyUser: state => {
            state.loading = true
        },
        updateCompanyUserSuccess: state => {
            state.loading = false
        },
        updateCompanyUserFailure: state => {
            state.loading = false
            state.hasErrors = true;
        },
        setselectedProductSuccess: (state, { payload }) => {
            state.selectedProduct = payload 
        },
        validateSignupRequestSuccess: (state, { payload }) => {
            state.clickStatus = payload.clickStatus
            state.signupRequest = payload.signupRequest
        },
        
    },
});

export const { getUsers, getUsersSuccess, getUsersFailure, addUser, addUserSuccess, addUserFailure,updateUserSuccess,
    getUserInfo, getUserInfoSuccess, getUserInfoFailure,createAuth0User, createAuth0UserSuccess, createAuth0UserFailure,
    createCompanyUser,updateCompanyUser,updateCompanyUserSuccess, updateCompanyUserFailure ,setselectedProductSuccess,
    validateSignupRequestSuccess} = usersSlice.actions;
export const usersSelector = state => state.users;
export default usersSlice.reducer;
export function fetchUsers() {
    return async dispatch => {
        dispatch(getUsers());
        try {
            const params = { callTime: new Date() };
            const response = await getAxios('api/SignUp/GetUsers', { params: params });
            dispatch(getUsersSuccess(response));
        } catch (error) {
            dispatch(getUsersFailure());
        }
    };
}

export const createSignup = (data) => {
    return async dispatch => {
        dispatch(addUser(data));
        try {
            const response = await postAxios('api/SignUp/CreateSignUp', { params: data });
            dispatch(addUserSuccess(response));
        } catch (error) {
            dispatch(addUserFailure());
        }
    }
};

export const updateUser = (data) => {
    return async dispatch => {
        dispatch(addUser(data));
        try {
            const response = await postAxios('api/SignUp/UpdateSignUp', { params: data });
            dispatch(updateUserSuccess(response));
        } catch (error) {
            dispatch(addUserFailure());
        }
    }
};
export const updateExistingUser = (data) => {
    return async dispatch => {
        dispatch(addUser(data));
        try {
            const response = await postAxios('api/SignUp/UpdateExistingUser', { params: data });
            dispatch(updateUserSuccess(response));
        } catch (error) {
            dispatch(addUserFailure());
        }
    }
};

export const createExistingUser = (data) => {
    return async dispatch => {
        dispatch(addUser(data));
        try {
            const response = await postAxios('api/SignUp/CreateExistingUser', { params: data });
            dispatch(updateUserSuccess(response));
        } catch (error) {
            dispatch(addUserFailure());
        }
    }
};
export const updateBusiness = (data) => {
    return async dispatch => {
        dispatch(addUser(data));
        try {
            const response = await postAxios('api/SignUp/UpdateBusiness', { params: data });
            dispatch(updateUserSuccess(response));
        } catch (error) {
            dispatch(addUserFailure());
        }
    }
};
export function fetchUserInfo(url) {
    return async dispatch => {
        dispatch(getUserInfo());
        try {
            const params = { callTime: new Date(),url:url };
            const response = await postAxios("api/NewUser/GetUserInfo", {
                params: params,
              });
            dispatch(getUserInfoSuccess(response));
        } catch (error) {
            dispatch(getUserInfoFailure());
        }
    };
}
export const triggerAuth0User = (data) => {
    return async dispatch => {
        dispatch(createAuth0User(data));
        try {
            const response = await postAxios('api/NewUser/CreateAuth0User', { params: data });
            dispatch(createAuth0UserSuccess(response));
        } catch (error) {
            dispatch(createAuth0UserFailure());
        }
    }
};

export const triggerUpdateCompanyUser = (data) => {
    return async dispatch => {
        dispatch(updateCompanyUser(data));
        try {
            const response = await postAxios('api/NewUser/UpdateCompanyUserInvite', { params: data });
            dispatch(updateCompanyUserSuccess(response));
        } catch (error) {
            dispatch(updateCompanyUserFailure());
        }
    }
};

export const setselectedProduct = (val) => {
    return async dispatch => { 
        try { 
            dispatch(setselectedProductSuccess(val));
        } catch (error) {
            
        }
    }
};
