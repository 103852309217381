import { configureStore } from "@reduxjs/toolkit";
import allReducers from "./reducers";
import logger from "redux-logger";


const Store = () => {
  const store = configureStore({reducer:allReducers,middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)});
  return store;
};

export default Store;